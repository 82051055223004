import React, { useState, useContext, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import context from 'utils/context';
import { useQuery, useSubscription, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Modal from 'commonComponents/modal/Modal';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ClearIcon from '@material-ui/icons/Clear';
import ReactTable from 'react-table';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import OrderDetails from './OrderDetails';
import DeliveryStatus from './DeliveryStatus';
import style from './Orders.module.scss';
import { ThemeContext } from 'index';

const REQUEST_SPARK_DELIVERY = gql`
	mutation reCreateOrderDeliveryByShopAdmin($orderId: ID!) {
		reCreateOrderDeliveryByShopAdmin(orderId: $orderId) {
			_id
			status
		}
	}
`;

const GET_ORDERS_SUBSCRIPTION = gql`
	subscription createOrder {
		createOrder {
			_id
			user {
				_id
				fullName
				phoneNumber
			}
			shipmentModel

			finalPrice
			commission
			createdAt
			finished
			status
			trip {
				_id
				trackId
				origin {
					coordinates
				}
				destinations {
					coordinates
				}
			}
			tracking {
				trackId
			}
			cart {
				products {
					quantity
					product {
						_id
						title
					}
				}
			}
		}
	}
`;

const UPDATE_ORDERS_SUBSCRIPTION = gql`
	subscription updateOrder {
		updateOrder {
			_id
			user {
				_id
				fullName
				phoneNumber
			}
			shipmentModel

			finalPrice
			commission
			createdAt
			finished
			status
			trip {
				_id
				trackId
				origin {
					coordinates
				}
				destinations {
					coordinates
				}
			}
			tracking {
				trackId
			}
			cart {
				products {
					quantity
					product {
						_id
						title
					}
				}
			}
		}
	}
`;

const GET_ORDERS = gql`
	query getOrdersByShopAdmin(
		$limit: Int
		$skip: Int
		$_id: ID
		$user: ID
		$shipmentModel: OrderShipmentModel
		$finalPrice: Float
		$commission: Float
		$finished: Boolean
		$tracking: OrderTrackingQueryInput
		$status: OrderStatus
		$createdAt: Date
		$createdAtSort: Int
		$finalPriceSort: Int
		$passengerName: String
		$passengerPhoneNumber: String
		$createdAtFrom: Date
		$finalPriceFrom: Float
	) {
		getOrdersByShopAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				user: $user
				shipmentModel: $shipmentModel
				tracking: $tracking
				finalPrice: $finalPrice
				commission: $commission
				finished: $finished
				status: $status
				createdAt: $createdAt
				passengerName: $passengerName
				passengerPhoneNumber: $passengerPhoneNumber
				createdAtFrom: $createdAtFrom
				finalPriceFrom: $finalPriceFrom
			}
			sort: { createdAt: $createdAtSort, finalPrice: $finalPriceSort }
		) {
			_id
			user {
				_id
				fullName
				phoneNumber
			}
			shop {
				name
			}
			shipmentModel
			finalPrice
			commission
			createdAt
			finished
			status
			trip {
				_id
				trackId
				origin {
					coordinates
				}
				destinations {
					coordinates
				}
			}
			tracking {
				trackId
			}
			cart {
				products {
					quantity
					product {
						_id
						title
					}
				}
			}
		}
	}
`;

const GET_ORDERS_COUNT = gql`
	query getOrdersByShopAdminCount(
		$_id: ID
		$user: ID
		$shipmentModel: OrderShipmentModel
		$finalPrice: Float
		$commission: Float
		$finished: Boolean
		$tracking: OrderTrackingQueryInput
		$status: OrderStatus
		$createdAt: Date
		$passengerName: String
		$passengerPhoneNumber: String
		$createdAtFrom: Date
		$finalPriceFrom: Float
	) {
		getOrdersByShopAdminCount(
			filters: {
				_id: $_id
				user: $user
				shipmentModel: $shipmentModel
				tracking: $tracking
				finalPrice: $finalPrice
				commission: $commission
				finished: $finished
				status: $status
				createdAt: $createdAt
				passengerName: $passengerName
				passengerPhoneNumber: $passengerPhoneNumber
				createdAtFrom: $createdAtFrom
				finalPriceFrom: $finalPriceFrom
			}
		)
	}
`;

const Orders = (props) => {
	const perPage = 100;
	const [pageCount, setPageCount] = useState(0);

	const setOrdersCount = useContext(context).setOrdersCount;

	const statusOptions = [
		'ALL',
		'PENDING',
		'PAID',
		'SHIPPING',
		'DELIVERED',
		'REJECTED',
		'DELIVERY NOT ACCEPTED',
		'FINISHED DUE TO NOT PAYING',
		'PREPARING',
		'PREPARED'
	];

	const shipmentModelOptions = ['ALL', 'SHOP', 'SPARK'];

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		createdAtSort: -1,
		status: undefined,
		createdAt: undefined,
		passengerName: undefined,
		passengerPhoneNumber: undefined,
		finalPrice: undefined,
		finalPriceSort: undefined,
		finished: false,
		tracking: { trackId: undefined, estimatedDelivery: undefined },
		createdAtFrom: undefined,
		finalPriceFrom: undefined,
	});

	const { loading, error, data, refetch } = useQuery(GET_ORDERS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_ORDERS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		refetch();
	}, [,]);

	const { error: subError, data: subData, loading: subLoading } = useSubscription(GET_ORDERS_SUBSCRIPTION);

	const { error: subUpdateError, data: subUpdateData, loading: subUpdateLoading } = useSubscription(
		UPDATE_ORDERS_SUBSCRIPTION
	);

	useEffect(() => {
		if (subUpdateData) {
			console.log('update order data is:', subUpdateData);
		}
		if (subData) {
			console.log('create order data is:', subData);
		}
		if (data) {
			console.log('get order data :', data);
		}
	}, [subUpdateData, subData, data, loading]);

	const themeContext = useContext(ThemeContext);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
		buttonColor: 'orange',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
		buttonColor: 'green',
	};
	const theme = themeContext === 'Purchase' ? greenTheme : orangeTheme;

	const [dateFromFilter, setDateFromFilter] = useState('');
	const [dateFromFilterError, setDateFromFilterError] = useState('');
	const [finalPriceFromFilter, setFinalPriceFromFilter] = useState('');

	const [priceFilter, setPriceFilter] = useState('');
	const [idFilter, setIdFilter] = useState('');
	const [passengerNameFilter, setPassengerNameFilter] = useState('');
	const [passengerPhoneNumberFilter, setPassengerPhoneNumberFilter] = useState('');
	const [trackIdFilter, setTrackIdFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');

	const [status, setStatus] = useState(statusOptions[0]);
	const [shipmentModelFilter, setShipmentModelFilter] = useState(shipmentModelOptions[0]);

	const [dateSort, setDateSort] = useState('down');
	const [priceSort, setPriceSort] = useState('');
	const [trackIdFilterError, setTrackIdFilterError] = useState(false);

	const [colorId, setColorId] = useState('');
	const [dateFilterError, setDateFilterError] = useState('');
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [modal, setModal] = useState(false);
	const [deliveryRequestModal, setDeliveryRequestModal] = useState(false);
	const [orderDelivery, setOrderDelivery] = useState();
	const [orderDetail, setOrderDetail] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectOrderDetail = (order) => {
		setModal(true);
		setOrderDetail(order);
	};

	const onSelectOrderDeliveryRequest = (order) => {
		setDeliveryRequestModal(true);
		setOrderDelivery(order);
	};

	useEffect(() => {
		setOrdersCount(list.length);
	}, [list]);

	const [excelData, setExcelData] = useState([
		['track ID', 'customer name', 'customer phone No', 'date', 'price', 'status', 'products'],
	]);

	const [getOrdersByShopAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_ORDERS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getOrdersByShopAdminCount > 0 &&
			excelData.length === countData.getOrdersByShopAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getOrdersByShopAdmin, (item) => [
					item.tracking.trackId,
					item.user ? item.user.fullName : '-',
					item.user ? item.user.phoneNumber : '-',
					moment(item.createdAt).format('MM-DD-YYYY'),
					item.finalPrice.toFixed(2),
					item.status.replace(/_/g, ' ').toLowerCase(),
					Array.from(item.cart.products, (element) => `${element.product.title}(${element.quantity})`).join(
						', '
					),
				]),
			]);
		}
		if (lazyError) {
			console.log(lazyError);
		}
	}, [, lazyData, lazyLoading, lazyError]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Orders.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getOrdersByShopAdmin({
				variables: {
					...variables,
					skip: 0,
					limit: countData.getOrdersByShopAdminCount,
				},
			});
		}
	};

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('up');
			}
		}
	};

	const onPriceSortChange = () => {
		if (!priceSort) {
			setPriceSort('up');
		} else {
			if (priceSort === 'up') {
				setPriceSort('down');
			} else {
				setPriceSort('up');
			}
		}
	};

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFromFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (trackIdFilter && trackIdFilter.length !== 10) {
			setTrackIdFilterError(true);
		} else {
			setTrackIdFilterError(false);
		}
	}, [trackIdFilter]);

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}

		if (priceSort) {
			if (priceSort === 'up') {
				setVariables((variables) => ({ ...variables, finalPriceSort: 1 }));
			} else if (priceSort === 'down') {
				setVariables((variables) => ({ ...variables, finalPriceSort: -1 }));
			}
		} else {
			setVariables((variables) => ({
				...variables,
				finalPriceSort: undefined,
			}));
		}
	}, [dateSort, priceSort]);

	const onFilterSubmit = () => {
		let hasFilter =
			trackIdFilter ||
			variables.tracking.trackId ||
			passengerNameFilter ||
			variables.passengerName ||
			passengerPhoneNumberFilter ||
			variables.passengerPhoneNumber ||
			dateFilter ||
			variables.createdAt ||
			priceFilter ||
			variables.finalPrice ||
			finalPriceFromFilter ||
			variables.finalPriceFrom ||
			dateFromFilter ||
			variables.createdAtFrom ||
			!(status === 'ALL' && !variables.status);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');

			if (passengerNameFilter) {
				setVariables((variables) => ({
					...variables,
					passengerName: passengerNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerName: undefined }));
			}

			if (passengerPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					passengerPhoneNumber: passengerPhoneNumberFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerPhoneNumber: undefined }));
			}

			if (trackIdFilter && trackIdFilter.length === 10) {
				setVariables((variables) => ({
					...variables,
					tracking: {
						trackId: trackIdFilter.trim(),
						estimatedDelivery: undefined,
					},
				}));
			} else if (!trackIdFilter) {
				setVariables((variables) => ({
					...variables,
					tracking: { trackId: undefined, estimatedDelivery: undefined },
				}));
			}

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
				console.log(moment(dateFilter).format('YYYY-MM-DD'));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (finalPriceFromFilter) {
				setVariables((variables) => ({
					...variables,
					finalPriceFrom: parseFloat(finalPriceFromFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, finalPriceFrom: undefined }));
			}

			if (priceFilter) {
				setVariables((variables) => ({
					...variables,
					finalPrice: parseFloat(priceFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, finalPrice: undefined }));
			}

			if (status === 'ALL') {
				setVariables((variables) => ({
					...variables,
					status: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					status: status.replace(/ /g, '_').toUpperCase(),
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			createdAtSort: -1,
			status: undefined,
			createdAt: undefined,
			passengerName: undefined,
			passengerPhoneNumber: undefined,
			finalPrice: undefined,
			finalPriceSort: undefined,
			finished: false,
			tracking: { trackId: undefined, estimatedDelivery: undefined },
			createdAtFrom: undefined,
			finalPriceFrom: undefined,
		});
		setDateFilter('');
		setPriceFilter('');
		setTrackIdFilter('');
		setPassengerNameFilter('');
		setPassengerPhoneNumberFilter('');
		setStatus('ALL');
		setPriceSort('');
		setDateSort('down');
		setFinalPriceFromFilter('');
		setDateFromFilter('');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onFilterSubmit();
		}
	};

	// const [list, setList] = useState([]);
	let list = [];

	if (data) {
		list = data.getOrdersByShopAdmin;
		window.localStorage.setItem('ordersCount', list.length);
		console.log('data is heree', data);
	}

	// const createOrderTimeOut = (createdOrder) => {
	//   const timeOut1 = setTimeout(() => {
	//     console.log("from createOrder timeout");
	//     let rest = list.filter((item) => item._id !== createdOrder._id);
	//     list = [{ ...createdOrder, color: false }, ...rest];
	//   }, 2000);
	//   // return () => clearTimeout(timeOut1);
	// };

	// const updateOrderTimeOut = (updatedOrder) => {
	//   const timeOut2 = setTimeout(() => {
	//     console.log("from updateOrder timeout");
	//     let rest = list.filter((item) => item._id !== updatedOrder._id);
	//     list = [{ ...updatedOrder, color: false }, ...rest];
	//   }, 2000);
	//   // return () => clearTimeout(timeOut2);
	// };

	// update orders lists after new order added

	if (subData) {
		// setColorId(subData.createOrder._id);
		list = [{ ...subData.createOrder, color: true }, ...list];
		// createOrderTimeOut(subData.createOrder);
		window.localStorage.setItem('ordersCount', list.length);
	}

	// update orders lists after an order has updated

	if (subUpdateData) {
		console.log(subUpdateData);
		if (subUpdateData.updateOrder.status === 'DELIVERED' || subUpdateData.updateOrder.status === 'REJECTED') {
			list = list.filter((item) => item._id !== subUpdateData.updateOrder._id);
			window.localStorage.setItem('ordersCount', list.length);
		} else {
			let rest = list.filter((item) => item._id !== subUpdateData.updateOrder._id);
			list = [{ ...subUpdateData.updateOrder, color: true }, ...rest];
			console.log('this is it::::::::', {
				...subUpdateData.updateOrder,
				color: true,
			});
			// console.log('shit');
			// updateOrderTimeOut(subUpdateData.updateOrder);
		}
	}

	// useEffect(() => {
	//   return setTimeout(() => {
	//     setColorId("");
	//   }, 2000);
	// }, [colorId]);

	useEffect(() => {
		console.log(list);
	}, [list]);

	// calculate total price
	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (data) {
			let number = 0;
			list.map((item) => {
				number = number + item.finalPrice;
			});
			setTotal(number);
		}
	}, [data, loading]);

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)} style={{ paddingBottom: '25px' }}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onFilterSubmit} color={theme.primaryColor}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
			</div>
			<ReactTable
				data={[
					...list.map((order) => ({
						passengerName: order.user && order.user.fullName,
						passengerPhoneNumber: order.user && order.user.phoneNumber,
						trackId: order.tracking && order.tracking.trackId && (
							<CopyToClipboard text={order.tracking.trackId}>
								<span
									title="Click To Copy To Clipboard"
									className={style.copyClipboard}
									style={{ color: order.color && '#f78902' }}
								>
									{order.tracking.trackId}
								</span>
							</CopyToClipboard>
						),
						date: (
							<span style={{ color: order.color && '#f78902' }}>
								{moment(order.createdAt).format('MM/DD/YYYY HH:mm')}
							</span>
						),
						status: order.status && (
							<div
								className={`${style.inlineTag} ${
									style[order.status.replace(/_/g, '').toLowerCase() + 'order' + 'Tag']
								}`}
							>
								{order.status.replace(/_/g, ' ').toLowerCase()}
							</div>
						),
						shipmentModel: <span style={{ color: order.color && '#f78902' }}>{order.shipmentModel}</span>,
						finalPrice: (
							<div>
								<span style={{ color: order.color && '#f78902' }}>
									{'$' + order.finalPrice.toFixed(2)}
								</span>
							</div>
						),
						products: (
							<div style={{ height: '80px', overflowY: 'auto', overflowX: 'hidden', paddingTop: '25px' }}>
								{' '}
								{order.cart.products.map((item) => (
									<div
										key={item.product._id}
										className={`${style.inlineTag} ${style[theme.primaryColor + 'Tag']}`}
									>
										{item.quantity}-{item.product.title}
									</div>
								))}
							</div>
						),
						actions: (
							<div className="actions-right">
								<button onClick={() => onSelectOrderDetail(order)} className={style.detailIconDiv}>
									<span>i</span>
								</button>

								<button
									onClick={() => onSelectOrderDeliveryRequest(order)}
									className={`${style.inlineButton} ${style.inlineEditButton}`}
								>
									delivery status
								</button>
							</div>
						),
					})),
					list.length > 0 && {
						trackId: <div className={style.totalNumber}>Total:</div>,
						finalPrice: <div className={style.totalNumber}> {'$' + total.toFixed(2)}</div>,
					},
				]}
				columns={[
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Track ID</div>
								{trackIdFilter && trackIdFilterError && (
									<div className={style.dateErrorDiv}>must be 10 character</div>
								)}
								<CustomInput
									value={trackIdFilter}
									onChange={(e) => setTrackIdFilter(e.target.value)}
									// id="regular"
									green={props.type === 'grocery'}
									warning={props.type === 'restaurant'}
									error={trackIdFilter && trackIdFilterError ? true : false}
									inputProps={{
										placeholder: 'track ID ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'trackId',
						sortable: false,
						filterable: false,
						width: 220,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Customer Name</div>
								<CustomInput
									value={passengerNameFilter}
									onChange={(e) => setPassengerNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Customer Phone No</div>
								<CustomInput
									value={passengerPhoneNumberFilter}
									onChange={(e) => setPassengerPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},

					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Date{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onPriceSortChange}>
									Price{' '}
									<span>
										{priceSort && (
											<span>
												{priceSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={finalPriceFromFilter}
									onChange={(e) => setFinalPriceFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={priceFilter}
									onChange={(e) => setPriceFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'finalPrice',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor={theme.primaryColor}
										buttonText={status}
										onClick={(e) => setStatus(e)}
										dropdownList={statusOptions}
									/>
								</div>
							</div>
						),
						accessor: 'status',
						sortable: false,
						filterable: false,
					},

					{
						Header: (
							<div>
								<div
									className={style.titleDiv}
									style={{
										display: 'inline-block',
										width: '100%',
										textAlign: 'center',
										marginBottom: '79px',
									}}
								>
									Products
								</div>
							</div>
						),
						accessor: 'products',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
					},
				]}
				defaultPageSize={Math.max(data.getOrdersByShopAdmin.length, list.length) + 1}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>

			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<OrderDetails
						type={themeContext}
						refetch={() => refetch()}
						close={() => setModal(false)}
						theme={theme}
						order={orderDetail}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deliveryRequestModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<DeliveryStatus
						type={themeContext}
						refetch={() => refetch()}
						close={() => setDeliveryRequestModal(false)}
						order={orderDelivery}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Orders;
