import React, { useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import Close from '@material-ui/icons/Close';
import Modal from 'commonComponents/modal/Modal';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.js';
import Badge from 'components/Badge/Badge.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import style from './OrderDetails.module.scss';

const DELIVERED = gql`
	mutation changeStatusToDeliveredByShopAdmin(
		$orderId: ID!
		$receiverName: String!
		$receiverPhoneNumber: String!
		$deliveryDate: Date!
	) {
		changeStatusToDeliveredByShopAdmin(orderId: $orderId, receiverName: $receiverName, receiverPhoneNumber: $receiverPhoneNumber, deliveryDate: $deliveryDate) {
			_id
		}
	}
`;

const DeliveryOrder = ({ orderId, close, type }) => {
	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const grayTheme = {
		checkboxColor: 'gray',
		primaryColor: 'primary',
	};

	let theme = orangeTheme;
	if (type === 'Purchase') {
		theme = greenTheme;
	} else if (type === 'Restaurant') {
		theme = orangeTheme;
	} else if (type === 'superAdmin') {
		theme = grayTheme;
	}

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const [receiverName, setReceiverName] = useState('');
	const [receiverPhoneNumber, setreceiverPhoneNumber] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [changeStatusToDeliveredByShopAdmin, { data, error, loading }] = useMutation(DELIVERED);

	const onSubmit = () => {
		if (!receiverName || receiverName.trim() === '') {
			setErrorText('Receiver name is required.');
			setErrorModal(true);
			return;
		}
		if (!receiverPhoneNumber || receiverPhoneNumber.trim() === '') {
			setErrorText('Receiver phone number is required.');
			setErrorModal(true);
			return;
		}
		if (!/^\+?\d+$/.test(receiverPhoneNumber)) {
			setErrorText('Invalid phone number format.');
			setErrorModal(true);
			return;
		}
		changeStatusToDeliveredByShopAdmin({
			variables: {
				orderId,
				receiverName: receiverName,
				receiverPhoneNumber: receiverPhoneNumber,
				deliveryDate: moment().toDate()
			},
		})
			.then((res) => {
				close();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setErrorText(err.graphQLErrors[0].message);
					setErrorModal(true);
				}
			});
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Prepared</h4>
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
					<label htmlFor="receiverName" style={{ marginRight: '10px' }}>Receiver Name:</label>
					<input 
						id="receiverName" 
						type="text" 
						placeholder="Receiver Name" 
						value={receiverName} 
						onChange={(e) => setReceiverName(e.target.value)} 
						style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', width: 'calc(100% - 100px)' }}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
					<label htmlFor="receiverPhoneNumber" style={{ marginRight: '10px' }}>Receiver Phone Number:</label>
					<input 
						id="receiverPhoneNumber" 
						type="text" 
						placeholder="Receiver Phone Number" 
						value={receiverPhoneNumber} 
						onChange={(e) => setreceiverPhoneNumber(e.target.value)} 
						style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', width: 'calc(100% - 100px)' }}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
					<label htmlFor="deliveryTime" style={{ marginRight: '10px' }}>Delivery Time:</label>
					<input 
						id="deliveryTime" 
						type="text" 
						placeholder="Delivery Time" 
						value={moment().format('YYYY/MM/DD HH:mm')} 
						readOnly 
						style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', width: 'calc(100% - 100px)' }}
					/>
				</div>
			</div>
			<div className={style.footerShow}>
				<Button onClick={close}>Cancel</Button>
				<Button onClick={onSubmit} color={theme.primaryColor}>
					Submit
				</Button>
			</div>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setErrorModal(false)} type="notif" text={errorText ? errorText : 'Error !'} />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default DeliveryOrder;
