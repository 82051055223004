import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Close from '@material-ui/icons/Close';
import Modal from 'commonComponents/modal/Modal';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.js';
import Badge from 'components/Badge/Badge.js';
import FunOrderMap from './FunOrderMap';
import RejectOrder from './RejectOrder';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import style from './OrderDetails.module.scss';
import PrepareOrder from './PrepareOrder';
import DeliveryOrder from './DeliveryOrder';

const REQUEST_SPARK_DELIVERY = gql`
	mutation reCreateOrderDeliveryByShopAdmin($orderId: ID!) {
		reCreateOrderDeliveryByShopAdmin(orderId: $orderId) {
			_id
			status
		}
	}
`;

const UPDATE_ORDERS_SUBSCRIPTION = gql`
	subscription updateOrder($orderId: ID!) {
		updateOrder(orderId: $orderId) {
			_id
			user {
				_id
			}
			shipmentModel
			type
			finalPrice
			commission
			createdAt
			finished
			status
			trip {
				_id
				trackId
				origin {
					coordinates
				}
			}
			tracking {
				trackId
			}
			cart {
				products {
					quantity
					product {
						_id
						title
					}
				}
			}
		}
	}
`;

const SEND_NOTIFICATION_TO_SINGLE_DRIVER_OR_USER = gql`
	mutation createNotificationByShopAdmin(
		$driver: ID
		$user: ID
		$title: String!
		$body: String!
		$type: NotificationType!
		$for: ForInput!
	) {
		createNotificationByShopAdmin(
			input: { user: $user, driver: $driver, title: $title, body: $body, type: $type, for: $for }
		) {
			_id
			body
		}
	}
`;

const DeliveryStatus = ({ order: item, close, model, refetch, type }) => {
	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const grayTheme = {
		checkboxColor: 'gray',
		primaryColor: 'primary',
	};

	let theme = orangeTheme;
	if (type === 'Purchase') {
		theme = greenTheme;
	} else if (type === 'Restaurant') {
		theme = orangeTheme;
	} else if (type === 'superAdmin') {
		theme = grayTheme;
	}

	const [rejectModal, setRejectModal] = useState(false);
	const [preparedModal, setPreparedModal] = useState(false);
	const [deliveryModal, setDeliveryModal] = useState(false);
	const [showMap, setShowMap] = useState(false);

	const [
		createNotificationByShopAdmin,
		{ data: sendNotificationToSingleDriverOrUserData, error: sendNotificationToSingleDriverOrUserError },
	] = useMutation(SEND_NOTIFICATION_TO_SINGLE_DRIVER_OR_USER);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [order, setOrder] = useState(item);

	const { error: subUpdateError, data: subUpdateData, loading: subUpdateLoading } = useSubscription(
		UPDATE_ORDERS_SUBSCRIPTION,
		{
			variables: { orderId: order._id },
		}
	);

	useEffect(() => {
		if (subUpdateData) {
			setOrder(subUpdateData.updateOrder);
		}
	}, [subUpdateData, subUpdateLoading]);

	const [reCreateOrderDeliveryByShopAdmin, { data: requestDeliveryData, error: requestDeliveryError }] = useMutation(
		REQUEST_SPARK_DELIVERY
	);

	const onRequestSendNotification = () => {
		createNotificationByShopAdmin({
			variables: {
				title: 'Your order is ready!',
				body: `Your order from ${order.shop.name}, is ready.`,
				type: 'IMPORTANT',
				user: order.user._id.trim(),
				for: 'USER',
			},
		})
	}

	const onRequestSparkDelivery = () => {
		reCreateOrderDeliveryByShopAdmin({ variables: { orderId: order._id } })
			.then((res) => {
				close();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Delivery Status</h4>
				</div>
			</div>
			<div className={style.infoContainer}>
				<div className={style.rowContainer}>
					<div>Status:</div>
					<div>{order.status.replace(/_/g, ' ').toLowerCase()}</div>
				</div>

				{order.status === 'SHIPPING' && order.trip && order.trip.origin && (
					<div className={style.toggleContainer}>
						{!showMap && (
							<div onClick={() => setShowMap(true)}>
								<div>show map</div>
								<ExpandMoreIcon></ExpandMoreIcon>
							</div>
						)}
						{showMap && (
							<div onClick={() => setShowMap(false)}>
								<div>hide map</div>
								<ExpandLessIcon></ExpandLessIcon>
							</div>
						)}
					</div>
				)}
				{showMap && (
					<div style={{ height: '250px' }} className={style.mapContainer}>
						{
							<FunOrderMap
								trackId={order.tracking.trackId}
								destination={{
									lat: order.trip.destinations && order.trip.destinations[0].coordinates[1],
									lng: order.trip.destinations && order.trip.destinations[0].coordinates[0],
								}}
								origin={{
									lat: order.trip.origin.coordinates[1],
									lng: order.trip.origin.coordinates[0],
								}}
								position={{
									lat: order.trip.origin.coordinates[1],
									lng: order.trip.origin.coordinates[0],
								}}
							/>
						}
					</div>
				)}
			</div>
			<div className={style.footerShow}>
				<Button onClick={close}>Close</Button>
				{(order.status === 'DELIVERY_NOT_ACCEPTED' || order.status === 'PAID' || order.status === 'PREPARING') && (
					<Button
						color="redButton"
						onClick={() => {
							setRejectModal(true);
						}}
					>
						Reject order
					</Button>
				)}
				{order.status === 'DELIVERY_NOT_ACCEPTED' && (
					<Button onClick={onRequestSparkDelivery} color={theme.primaryColor}>
						Request spark delivery
					</Button>
				)}
				{order.status === 'PREPARING' && (
					<Button onClick={() => setPreparedModal(true)} color={theme.primaryColor}>
						prepared
					</Button>
				)}
				{order.status === 'PREPARED' && (
					<Button onClick={onRequestSendNotification} color={theme.primaryColor}>
						send notification
					</Button>
				)}
				{order.status === 'PREPARED' && (
					<Button onClick={() => setDeliveryModal(true)} color="success">
						delivery
					</Button>
				)}
			</div>
			<Dialog open={rejectModal} transition={Transition} onClose={() => setRejectModal(false)}>
				<DialogContent id="modal-slide-description">
					<RejectOrder
						type={type}
						close={() => {
							setRejectModal(false);
							close();
							refetch();
						}}
						orderId={order._id}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={preparedModal} transition={Transition} onClose={() => setPreparedModal(false)}>
				<DialogContent id="modal-slide-description">
					<PrepareOrder
						type={type}
						close={() => {
							setPreparedModal(false);
							close();
							refetch();
						}}
						orderId={order._id}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deliveryModal} transition={Transition} onClose={() => setDeliveryModal(false)}>
				<DialogContent id="modal-slide-description">
					<DeliveryOrder
						type={type}
						close={() => {
							setDeliveryModal(false);
							close();
							refetch();
						}}
						orderId={order._id}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default DeliveryStatus;
