import React, { useState, useEffect } from 'react';

import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.js';
import style from './Modal.module.scss';

const Modal = (props) => {
	// const handleEnter = (e) => {
	// 	if (e.keyCode === 13) {
	// 		if(props.func){
	// 			props.func();
	// 		}else{
	// 			props.close()
	// 		}

	// 	}
	// };

	// document.addEventListener('keydown', handleEnter);

	return (
		<div className={style.mainDiv}>
			<form>
				<div className={style.header}>
					<div>
						<Button justIcon key="close" aria-label="Close" color="transparent" onClick={props.close}>
							<Close />
						</Button>
					</div>
				</div>
				<div className={style.content}>
					<div>{props.text}</div>
				</div>
				{
					props.children ? props.children
					:
					<>
						{props.type !== 'notif' && (
							<div className={style.footer}>
								<Button style={{ textTransform: 'capitalize' }} color="yellow" onClick={props.func}>
									Yes
								</Button>
								<Button style={{ textTransform: 'capitalize' }} color="whiteButton" onClick={props.close}>
									No
								</Button>
							</div>
						)}

						{props.type === 'notif' && (
							<div className={style.footer}>
								<Button style={{ textTransform: 'uppercase' }} color="yellow" onClick={props.close}>
									OK
								</Button>
							</div>
						)}
					</>
				}

			</form>
		</div>
	);
};

export default Modal;
